import React, { useState, useEffect } from "react";
import { Button, Container, Col, Card, Icon,} from "@edx/paragon";
import { getConfig } from "@edx/frontend-platform";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Logo from '../../assets/images/BCG_placholder-logo.png';

const Footer = () => {

    return (
        <footer className="footer-wrapper">
            <div className="footer-flex-layout">
                <img className="logo" src={Logo} alt="footer-logo"/>
                {/* <div className="social-links-container">
                
                        <span className="social-icon"><a href="https://www.facebook.com">
                             <FontAwesomeIcon icon={faFacebookF} />
                                </a></span>
                        <span className="social-icon">
                        <a href="https://www.twitter.com">
                        <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        </span>

                        <span className="social-icon">
                            <a href="#">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </span>

                </div> */}
            </div>

            <div className="copyright-container mt-4">
            <span><a href="" alt="">Contact </a></span> 
            <span><a href="" alt="">About </a></span> 
            <span><a href="" alt="">Apply </a></span>
            <span><a href="" alt="">Privacy Policy </a></span> 
            <span><a href="" alt="">Terms of Use</a></span></div>

        </footer>
    )
}


export default Footer;