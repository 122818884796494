import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Col, Card, Icon,} from "@edx/paragon";
import { Add, Album, AccessAlarms} from '@edx/paragon/icons';
import { KeyboardArrowDown, Handshake, FilterCenterFocus, Radar, PlayArrow, ArrowDropDown, Email, BlurCircular, FileDownload} from '@edx/paragon/icons';
import Row from "react-bootstrap/Row";
import axios from "axios";
import { slice, concat } from "lodash";

import { getConfig } from "@edx/frontend-platform";

import CourseCard from "../Card/Card";
import AboutUS from "../About-us/About";
import Navigation from "../Navigation/navigation";
import Footer from "../Footer/Footer";
import "./Landing-page.scss";
import Brochure from "../../assets/images/archipelagos_brochure_2023.pdf";
import DigitalBrochure from "../../assets/images/BrochureDigitale_ENG.pdf";
import qrImage from "./QR_Code.png"

// const pdfPath = require('./brochure.pdf').default;

const URL_Courses = getConfig().LMS_BASE_URL + "/api/courses/v1/courses/";
// const URL_Courses = "http://localhost:8000" + "/api/courses/v1/courses/";

const LIMIT = 4;

const LandingPage = () => {
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(LIMIT);
  
 

  const update_data = async function () {
    console.log(URL_Courses)
    try {
        const result = await axios(URL_Courses, {
            params: { page_size: 100 },
          });
          setData(result.data.results);
        
    } catch (error) {
        console.log(error);
    }
   
  };

  const handlePdfDownloadAndOpen = () => {
    
    const pdfUrl = Brochure;

    // Create a new window/tab to open the PDF
    window.open(pdfUrl, '_blank');

    // Trigger the download of the PDF
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = "archipelagos_brochure_2023.pdf"; // Set the default filename for the downloaded file

    // Trigger a click event on the anchor element to download the PDF
    downloadLink.click();
  }

  const handlePdfDownloadBusiness = () => {
    const pdfUrl = DigitalBrochure;
    
    // Replace "business-brochure.pdf" with the desired filename "BrochureDigitale_ENG.pdf"
    const desiredFilename = "BrochureDigitale_ENG.pdf";
    
    // Create a new window/tab to open the PDF
    window.open(pdfUrl, '_blank');
    
    // Trigger the download of the PDF with the desired filename
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = desiredFilename; // Set the desired filename for the downloaded file
    
    // Trigger a click event on the anchor element to download the PDF
    downloadLink.click();
}


  useEffect(() => {
    update_data();
    
  }, []);

  const scrollTargetRef = useRef(null);

  const handleScrollDown = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  

  return (
    <div>
      
      <div className="section-1">
      <Navigation/>
        <div className="section-content-wrapper">
          <h1 className="heading-main">Your learning journey towards the capital markets starts now through the Archipelagos Program</h1>

        </div>
        <div onClick={handleScrollDown} className="scroll-down-container text-center">
             <h4>scroll down</h4>
             <Icon src={KeyboardArrowDown} className="scroll-down-icon m-auto"/>
          </div>

          <div className="sign-in-banner">
            <div className="section-content-wrapper sign-in-banner-flex-layout">
              <div className="sign-in-banner-content">
                <h1 className="mb-4 sign-in-banner-content-title">Archipelagos Program at a glance</h1>
              <p>Archipelagos is a <strong>multi-year international cooperation program developed by Cassa Depositi e Prestiti</strong>, the Italian Financial Institution for International Development Cooperation, <strong>with the support of the European Commission</strong>.</p> 

              <p><strong>Up to 1000+ SMEs</strong> will be selected across the African continent to join a <strong>dedicated capacity building Program</strong> designed to strengthen their capabilities and increase their readiness for capital markets.</p>

              <p>The selected SMEs will embark on a <strong>tailored learning journey</strong> through the <strong>Archipelagos digital platform</strong>, where they will have access to <strong>training, mentoring, and networking</strong> opportunities.</p>

              <p>At the end of this Program, the most promising SMEs will receive <strong>specialized support for raising funds</strong> in the capital markets through <strong>innovative debt instruments</strong>, supported by a <strong>guarantee facility from the European Commission</strong>.</p>

              <p>Inspired by the <strong>Team Europe Initiative of Investing in Young Business in Africa</strong> (TEI IYBA), also <strong>young university graduates / young entrepreneurs</strong> will have access to the <strong>capacity building Program</strong> to strengthen their business skills and sharpen their knowledge.</p>
              </div>
              <div className="mt-4 sign-in-banner-btn">
              <a className="" href={getConfig().LMS_BASE_URL + "/dashboard"}><Button variant="" className="btn-explore-courses mb-2 mb-sm-0">Sign in</Button>
               </a>
               </div>
            </div>
          </div>
      </div>

      <div ref={scrollTargetRef} className="section-2">
     <div className="section-content-wrapper text-center">
      <h1>Program objectives</h1>
      <h2 className="mt-3">The Archipelagos Program pursues <strong>3 overarching objectives</strong>:</h2>
     <div className="three-card-flex-layout">
       
     <Card className="">
          <Icon src={Handshake} />
          <Card.Section className="text-center">
            <p><strong>Supporting the growth of African SMEs</strong> through the Archipelagos capacity-building Program with tailored training, mentoring, and networking, powered by a dedicated digital platform.</p>
          
          </Card.Section>
          </Card>

          <Card className="">
          <Icon src={FilterCenterFocus} />
          <Card.Section className="text-center">
            <p><strong>Facilitating SMEs access to capital markets</strong> through the collaboration with local stock exchanges and a dedicated guarantee facility from the European Commission for the structuring of innovative financial instruments.</p>
          
          </Card.Section>
          </Card>

          <Card className="">
          <Icon src={Radar} className="section-4-icon"/>
          <Card.Section className="text-center">
            <p><strong>Creating a more favorable ecosystem for SMEs</strong> through the dialogue with local policy makers and institutions, and the engagement of key stakeholders.</p>
          
          </Card.Section>
          </Card>
      </div>
 
      </div>
     </div>

      <div className="section-3">
        <div className="section-content-wrapper">
        <h1 className="text-center">Key information</h1>
          <div className="section-3-flex mt-4">
    
    <section>
    <Card >
      <Card.Section className="text-center">
        <p className="section-3-body-text"><span>Donor: </span>European Commission</p>
        <hr/>
        <p className="section-3-body-text"><span>Donor’s contribution: </span>€ 30 million Guarantee facility and € 5 million Technical Assistance</p>
        <hr/>
        <p className="section-3-body-text"><span>Implementing Institution: </span>Cassa Depositi e Prestiti (CDP)</p>
        <hr/>
        <p className="section-3-body-text"><span>Partners: </span>Local Stock Exchanges, International Investment Banks, Financial Intermediaries</p>
        <hr/>
        <p className="section-3-body-text"><span>Beneficiaries: </span>Small and Medium Enterprises (SMEs), and young university graduates / young entrepreneurs (capacity building only)</p>
        <hr/>
        <p className="section-3-body-text"><span>Geographical coverage: </span>All African countries, with Morocco, South Africa, Kenya and Ivory Coast acting as key Hubs</p>
        
      </Card.Section>
    </Card>
    </section>
    </div>
    </div>
      </div>

      <div className="section-4">
        <div className="section-content-wrapper">
        <h1 className="text-center">What we offer</h1>
        <div className="section-4-two-card-layout mt-4">
        <div className="section-4-subsection-flex-layout">
          <Icon className="section-4-icon" src={PlayArrow} />
            <div>
              <h3>Capacity building Program</h3>
              <p>CDP and the European Commission, through the project Archipelagos, recognize the importance of supporting the growth and development of African SMEs. To this end, CDP has designed a <strong>dedicated capacity building Program</strong> aimed at strengthening SMEs industrial and operational capabilities and preparing them to access the capital markets.</p> 

              <p>The Program is <strong>structured around 3 core areas - training, mentoring, and networking</strong> - and is <strong>delivered through a hybrid model</strong>, combining both asynchronous and synchronous components to offer a comprehensive and interactive learning experience.</p>
              
              <p>Through the <strong>Archipelagos digital platform</strong>, SMEs will have access to a vast learning catalogue of online courses, with <strong>differentiated learning paths</strong> according to the specific needs and stage of development. On top of the asynchronous component, <strong>webinars</strong> will contribute to make the learning journey even more stimulating, engaging, and interactive.</p>

              <p>In addition to training, SMEs participating in the Program will also receive <strong>dedicated support</strong> and will have <strong>access to a community of SMEs and investors</strong> providing them the opportunity to make valuable business connections, also at international level.</p>

              <p><strong>Access</strong> to the <strong>Archipelagos capacity building platform</strong> will be provided also to <strong>young university graduates / young entrepreneurs</strong> that are willing to enhance their business knowledge and entrepreneurial skills.</p>

            </div>
          </div>

          <div className="section-4-subsection-flex-layout">
          <Icon src={PlayArrow} />
            <div>
              <h3>Access to capital markets</h3>
              <p>The Archipelagos Program offers a unique opportunity for SMEs to <strong>access the capital they need to grow and scale up their business</strong>.</p>

              <p>At the end of the capacity building Program, the <strong>SMEs that demonstrate the readiness and potential to raise funds</strong> in the capital markets will be provided with tailored support and <strong>guided through the fundraising process.</strong></p>
               
              <p>Selected SMEs will in fact receive <strong>dedicated mentorship and coaching</strong> to best prepare for the <strong>interaction with potential investors</strong> (e.g., support in preparing a compelling investment pitch).</p> 
                
              <p>Finally, they will be <strong>supported in the structuring of innovative debt instruments</strong> and secure funding at favorable terms thanks to the first loss <strong>financial guarantee facility from the European Commission</strong> that will enable attracting other investors.</p>

            </div>
          </div>

        </div>
        
        

       
    </div>
      </div>

      <div className="section-4-what-we-looking-for-section">
      <div className="section-content-wrapper">
      <h1 className="text-center mb-4">We are looking for participants willing to:</h1>
        <ul className="section-4-bullet-points">
          <li><span>Develop new skills and capabilities </span>by acquiring knowledge and expertise to grow their business and boost competitiveness.</li>
          <li><span>Look for guidance and support </span>from experienced professionals to navigate new challenges and receive actionable advice to achieve their goals.</li>
          <li><span>Connect with other businesses </span>and establish valuable connections that can help them grow and succeed, by sharing experiences, knowledge, and best practices.</li>
          <li><span>Want to explore potential collaborations </span>and partnerships that can support them in achieving their business objectives.</li>
          <li><span>Pursue the chance to access debt capital </span>to finance their growth, by connecting with a range of potential investors and differentiating their sources of funding.</li>
        </ul>

        <p>Each of the selected SMEs will receive access to the platform for up to 3 key figures within the organization (e.g., founder, finance manager, strategy manager).</p>

        <p>In addition to SMEs, the capacity building platform is also open to <strong>young university graduates / young entrepreneurs</strong> that <strong>want to boost their academic journey</strong> and prepare to enter into the business world as well as those who <strong>look for strengthening their entrepreneurial skills</strong>.</p>
      </div>
      </div>

      <div className="section-5">
     <div className="section-5-content-wrapper text-center">
      <h1 className="mb-4">How to apply</h1>
      <h2>Are you willing to <strong>apply and join the Program</strong> to grow your company and enhance your business skills? Click on the link below and apply through the dedicated form </h2>
      <div className="mt-3 qr-container-layout">
        <a href="https://www.113.vovici.net/se/13B2588B1E1C3ACA" alt="application form link">Application form</a>
        <a href="https://www.113.vovici.net/se/13B2588B1E1C3ACA" className="mt-2" border="0"  rel="nofollow" alt="QR application form link"><img src={qrImage} className="QR-image" alt="QR code"/></a>
      </div>

      </div>

     </div>

      <div className="section-6">
     <div className="section-content-wrapper text-center">
     <div className="">
      <h2>For more information on the Archipelagos Program and for any question:</h2>
      <div className="mt-3 section-6-flex-layout-links">
       <a href="mailto:infoarchipelagos@cdp.it" className="section-6-link" alt="company email"><Icon src={Email} className=""/> Contact us</a>
       <a href="" onClick={handlePdfDownloadAndOpen} className="section-6-link" alt="Brochure" download><Icon src={FileDownload} className=""/>Download the Brochure of the Archipelagos Program</a>
       </div>

      </div>
     
      </div>
     </div>

     <div className="section-6">
     <div className="section-content-wrapper text-center business-matching-section">
     <div className="">
      <h2>Expand your business internationally with CDP Business Matching</h2>
      <p><strong>Join Business Matching, the international network connecting Italian companies and African partners</strong></p>
      <div className="mt-3 section-6-flex-layout-links">
      <div className="mt-2 sign-in-banner-btn">
              <a className="" href="https://businessmatching.cdp.it/en/welcome"><Button variant="" className="btn-explore-courses mb-2 mb-sm-0">Sign in</Button>
               </a>
               </div>
       <a href="" onClick={handlePdfDownloadBusiness} className="section-6-link" alt="Brochure" download><Icon src={FileDownload} className=""/>Download the Brochure of the CDP Business Matching Program</a>
       </div>

      </div>
     
      </div>
     </div>


      
     <Footer/>
    </div>
  );
};

export default LandingPage;